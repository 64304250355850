<template>
    <div>
        <!-- 顶部工具条 -->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label="单位人员数量:" placeholder="请选择单位人员数量">
                    <el-select v-model="filters.notification" clearable>
                        <el-option 
                            v-for="item in notificationArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='市：'>
                    <el-select v-model="filters.cityID" clearable  @change='selCity' ref ='cityRef' placeholder="请选择">
                        <el-option
                            v-for="item in cityIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='区/县：'>
                    <el-select v-model="filters.regionID" ref ='regionRef' clearable placeholder="请选择">
                        <el-option
                            v-for="item in regionIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="地区:">
                    <el-cascader :props="props" v-model="filters.selecAreaArr" clearable></el-cascader>
                </el-form-item> -->
                <el-form-item label='关键字:'>
                    <el-input placeholder="请输入单位名称" 
                        v-model="filters.searchKey"
                        clearable
                    >
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="岗位:" placeholder="请选择岗位">
                    <el-select v-model="filters.station" clearable>
                        <el-option 
                            v-for="item in stationArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="行业:" placeholder="请选择行业">
                    <el-select v-model="filters.trade" clearable>
                        <el-option 
                            v-for="item in tradeArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="状态:" placeholder="请选择状态">
                    <el-select v-model="filters.status" clearable>
                        <el-option 
                            v-for="item in statusArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="最新填报:" placeholder="请选择最新填报">
                    <el-select v-model="filters.isNewFillIn" clearable>
                        <el-option 
                            v-for="item in isNewFillInArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <!-- 表格 -->
        <el-table 
            v-loading="tableLoading"
            :data="tableData" 
            style="width:100%" 
            border 
            ref="multipleTable"
            highlight-current-row
            @current-change="handlerowChange"
            @selection-change="handleSelectChange"
        >
            <!-- <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column> -->
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column  label="市" width="" align="center" prop="City"></el-table-column>
            <el-table-column  label="区/县" width="" align="center" prop="Region"></el-table-column>
            <el-table-column  label="单位名称" min-width="130" align="center" prop="UnitName"></el-table-column>
            <el-table-column  label="所属行业" min-width="120" align="center" prop="TradeName"></el-table-column>
            <el-table-column label="法人" width="" align="center" prop="ContactPerson"></el-table-column>
            <el-table-column label="法人手机号" min-width="130" align="center" prop="ContactPersonPhoneNumber"></el-table-column>
            <el-table-column label="责任人" width="" align="center" prop="LiablePerson"></el-table-column>
            <el-table-column label="责任人手机号" min-width="130" align="center" prop="LiablePersonPhoneNumber"></el-table-column>
            <el-table-column label="管理人" width="" align="center" prop="Administrator"></el-table-column>
            <el-table-column label="管理人手机号" min-width="130" align="center" prop="AdministratorPhoneNumber"></el-table-column>
            <el-table-column  label="填报执行人" min-width="100" align="center" prop="RequestSource">
                <template slot-scope="scope">
                    <el-button @click="viewReportingExecutor(scope.row)" type="text">{{scope.row.ReportingExecutor}}</el-button>
                </template>
            </el-table-column>
            <el-table-column  label="本单位人员" min-width="100" align="center" prop="RequestSource">
                <template slot-scope="scope">
                    <el-button @click="viewUnitStaffs(scope.row)" type="text">{{scope.row.UnitStaffs}}</el-button>
                </template>
            </el-table-column>
            <el-table-column  label="学习时长大于0人数" min-width="150" align="center" prop="RequestSource">
                <template slot-scope="scope">
                    <el-button @click="viewStudyTimer(scope.row)" type="text">{{scope.row.StudyStaffs}}</el-button>
                    <!-- <span type="text">{{scope.row.StudyStaffs}}</span> -->
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-col :span="24" class="pageBarContainer">
            <!-- <div>已选择<span class="selectNumCla">{{allSelect.length}}</span>条</div> -->
            <div>
                <el-pagination
                    small
                    :page-sizes="pages.pageArr"
                    :page-size="pages.pageSize"
                    :current-page="pages.pageIndex"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pages.dataCount"
                    @size-change="handleSizeChange" 
                    @current-change="handleCurrentChange"
                    class="pageBar"       
                >
                </el-pagination>
            </div>
        </el-col>
        <!-- 填报执行人 -->
        <el-dialog
            title="填报执行人"
            :visible.sync="addFormVisibleDialog1"
            v-model="addFormVisibleDialog1"
            :close-on-click-modal="true"
        >
            <el-table 
                v-loading="tableLoading1"
                :data="tableData1" 
                style="width:100%" 
                border 
                ref="multipleTable"
                highlight-current-row
            >
                <el-table-column label="姓名" width="" align="center" prop="Name"></el-table-column>
                <el-table-column label="手机号" min-width="130" align="center" prop="PhoneNumber"></el-table-column>
                <el-table-column label="岗位（人群）" width="" align="center" prop="StationName"></el-table-column>
                <el-table-column  label="来源" min-width="100" align="center" prop="ResourceType">
                    <template slot-scope="scope">
                        <el-tag type="" v-if="scope.row.ResourceType == 0">数据中台</el-tag>
                        <el-tag type="success" v-else-if="scope.row.ResourceType == 1">单位填报</el-tag>
                        <el-tag type="info" v-else-if="scope.row.ResourceType == 2">大数据甄选</el-tag>
                        <el-tag type="warning" v-else-if="scope.row.ResourceType == 3">自主报考</el-tag>
                        <el-tag type="danger" v-else>未知</el-tag>
                    </template>
                </el-table-column>
                <el-table-column  label="是否本单位人员" min-width="" align="center" prop="IsOurUnit">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.IsOurUnit">是</el-tag>
                        <el-tag type="danger" v-else>否</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-col :span="24" class="pageBarContainer">
                <!-- <div>已选择<span class="selectNumCla">{{allSelect.length}}</span>条</div> -->
                <div>
                    <el-pagination
                        small
                        :page-sizes="pages1.pageArr"
                        :page-size="pages1.pageSize"
                        :current-page="pages1.pageIndex"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pages1.dataCount"
                        @size-change="handleSizeChange1" 
                        @current-change="handleCurrentChange1"
                        class="pageBar"       
                    >
                    </el-pagination>
                </div>
            </el-col>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisibleDialog1 = false">取消</el-button>
                <!-- <el-button type="primary" @click.native="submitFormData" :loading="submitFormDataLoading">确定</el-button> -->
            </div>
        </el-dialog>
        <!-- 本单位人员 -->
        <el-dialog
            title="本单位人员"
            :visible.sync="addFormVisibleDialog2"
            v-model="addFormVisibleDialog2"
            :close-on-click-modal="true"
        >
            <el-table 
                v-loading="tableLoading2"
                :data="tableData2" 
                style="width:100%" 
                border 
                ref="multipleTable"
                highlight-current-row
            >
                <el-table-column label="姓名" width="" align="center" prop="Name"></el-table-column>
                <el-table-column label="手机号" min-width="130" align="center" prop="PhoneNumber"></el-table-column>
                <el-table-column label="岗位（人群）" width="" align="center" prop="StationName"></el-table-column>
                <el-table-column  label="来源" min-width="100" align="center" prop="ResourceType">
                    <template slot-scope="scope">
                        <el-tag type="" v-if="scope.row.ResourceType == 0">数据中台</el-tag>
                        <el-tag type="success" v-else-if="scope.row.ResourceType == 1">单位填报</el-tag>
                        <el-tag type="info" v-else-if="scope.row.ResourceType == 2">大数据甄选</el-tag>
                        <el-tag type="warning" v-else-if="scope.row.ResourceType == 3">自主报考</el-tag>
                        <el-tag type="danger" v-else>未知</el-tag>
                    </template>
                </el-table-column>
                <el-table-column  label="是否执行填报" min-width="100" align="center" prop="IsFill">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.IsFill">是</el-tag>
                        <el-tag type="danger" v-else>否</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-col :span="24" class="pageBarContainer">
                <!-- <div>已选择<span class="selectNumCla">{{allSelect.length}}</span>条</div> -->
                <div>
                    <el-pagination
                        small
                        :page-sizes="pages2.pageArr"
                        :page-size="pages2.pageSize"
                        :current-page="pages2.pageIndex"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pages2.dataCount"
                        @size-change="handleSizeChange2" 
                        @current-change="handleCurrentChange2"
                        class="pageBar"       
                    >
                    </el-pagination>
                </div>
            </el-col>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisibleDialog2 = false">取消</el-button>
                <!-- <el-button type="primary" @click.native="submitFormData" :loading="submitFormDataLoading">确定</el-button> -->
            </div>
        </el-dialog>
        <!-- 学习时长大于0人数 -->
        <el-dialog
            title="学习时长大于0人数"
            :visible.sync="addFormVisibleDialog3"
            v-model="addFormVisibleDialog3"
            :close-on-click-modal="true"
        >
            <el-table 
                v-loading="tableLoading3"
                :data="tableData3" 
                style="width:100%" 
                border 
                ref="multipleTable"
                highlight-current-row
            >
                <el-table-column label="姓名" width="" align="center" prop="Name"></el-table-column>
                <el-table-column label="手机号" min-width="" align="center" prop="PhoneNumber"></el-table-column>
                <el-table-column label="岗位（人群）" min-width="" align="center" prop="StationName"></el-table-column>
                <el-table-column label="行业（场所）" min-width="" align="center" prop="TradeName"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-col :span="24" class="pageBarContainer">
                <!-- <div>已选择<span class="selectNumCla">{{allSelect.length}}</span>条</div> -->
                <div>
                    <el-pagination
                        small
                        :page-sizes="pages3.pageArr"
                        :page-size="pages3.pageSize"
                        :current-page="pages3.pageIndex"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pages3.dataCount"
                        @size-change="handleSizeChange3" 
                        @current-change="handleCurrentChange3"
                        class="pageBar"       
                    >
                    </el-pagination>
                </div>
            </el-col>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisibleDialog3 = false">取消</el-button>
                <!-- <el-button type="primary" @click.native="submitFormData" :loading="submitFormDataLoading">确定</el-button> -->
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Qs from 'qs' 
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
// 数据请求
import { getSocialUnitChaosForMarketing,getExecutorByUnitId,getStaffsByUnitId,getLearnStaffsByUnitId,getDataDictionaryList,getAreaDictionaryList,addSocialGroup,getSocialUnitListPage,exportSocialUnitStaffPageListChaos,updateSocialUnitStaffByChaos } from '@/api/api'
import { validPhone } from "../../../util/validate";
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList: [],
            filters:{
                searchKey:'',
                provinceID: 1919,
                cityID: '',
                regionID: '',
                selecAreaArr: [],
                station: '',
                trade: '',
                notification: '',
                status: '',
                isNewFillIn: '',
            },
            cityIDArr: [],
            regionIDArr: [],
            stationArr: [], //岗位
            tradeArr: [], //行业
            // 通知次数
            notificationArr: [
                {name: "大于3",value: 3},
                {name: "大于5",value: 5},
                {name: "大于10",value: 10},
                {name: "大于15",value: 15},
                {name: "大于20",value: 20},
                {name: "大于50",value: 50},
                {name: "大于100",value: 100},
            ],
            // 状态
            statusArr: [
                {name: "未注册",value: 0},
                {name: "已注册",value: 1},
                {name: "已报考",value: 2},
                {name: "已取证",value: 3},
            ],
            // 状态
            isNewFillInArr: [
                {name: "是",value: 0},
                {name: "否",value: -1},
            ],
            // 批次分页
            brandListPage: {
                DataCount: 10,
                pageIndex: 1,
                pageSize: 10,
            },
            // 表格
            tableData: [],
            tableLoading: false,
            allSelect: '',
            currentRow: '',
            // 分页
            pages:{
                pageArr:[10,20,30,40],
                pageSize:20,
                pageIndex:1,
                dataCount:1,
            },
            // 地区级联选择
            props: {
                lazy: true,
                checkStrictly: true,
                lazyLoad (node, resolve) {
                    var level = false
                    if(node.value > 0) {
                        var params = {
                            parentId: node.value,
                            level: 2,
                        }
                        level = true
                    } else {
                        var params = {
                            parentId: 1919,
                            level: 1,
                        }
                        level = false
                    }
                    getAreaDictionaryList(params).then(res => {
                        if(res.data.Success){
                            var nodes = res.data.Response.reverse()
                            nodes.forEach(item => {
                                item.value = item.ID
                                item.label = item.Name
                                item.leaf = level
                            })
                            resolve(nodes);
                        }
                    })
                }
            },
            needData: "",
            // 填报执行人
            addFormVisibleDialog1: false,
            tableLoading1: false,
            tableData1: [],
            pages1:{
                pageArr:[10,20,30,40],
                pageSize:20,
                pageIndex:1,
                dataCount:1,
            },
            // 本单位人员
            addFormVisibleDialog2: false,
            tableLoading2: false,
            tableData2: [],
            pages2:{
                pageArr:[10,20,30,40],
                pageSize:20,
                pageIndex:1,
                dataCount:1,
            },
            // 学习时长大于0人数
            addFormVisibleDialog3: false,
            tableLoading3: false,
            tableData3: [],
            pages3:{
                pageArr:[10,20,30,40],
                pageSize:20,
                pageIndex:1,
                dataCount:1,
            },
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查询
        getTableData() {
            this.pages.pageIndex = 1
            this.getTableDataFun();
        },
        // 列表
        getTableDataFun() {
            var params = {
                keyWord: this.filters.searchKey,
                provinceID: this.filters.provinceID,
                cityID: this.filters.cityID,
                regionID: this.filters.regionID,
                stationID: this.filters.station,
                tradeID: this.filters.trade,
                staffCounts: this.filters.notification,
                status: this.filters.status,
                originID: this.filters.isNewFillIn,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            this.tableLoading = true
            getSocialUnitChaosForMarketing(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.tableLoading = false
                    this.tableData = result.Response.Data
                    this.pages.dataCount = result.Response.DataCount
                }
            })
        },
        // 查看填报执行人
        viewReportingExecutor(val) {
            this.addFormVisibleDialog1 = true
            this.tableLoading1 = false
            this.tableData1 = []
            this.needData = val
            this.getTableDataFun1()
        },
        getTableDataFun1() {
            var params = {
                socialUnitId: this.needData.ID,
                pageIndex: this.pages1.pageIndex,
                pageSize: this.pages1.pageSize,
            }
            this.tableLoading1 = true
            getExecutorByUnitId(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.tableLoading1 = false
                    this.tableData1 = result.Response ? result.Response.Data : []
                    this.pages1.dataCount = result.Response ? result.Response.DataCount : 0
                }
            })
        },
        // 查看本单位人员
        viewUnitStaffs(val) {
            this.addFormVisibleDialog2 = true
            this.tableLoading2 = false
            this.tableData2 = []
            this.needData = val
            this.getTableDataFun2()
        },
        getTableDataFun2() {
            var params = {
                socialUnitId: this.needData.ID,
                pageIndex: this.pages2.pageIndex,
                pageSize: this.pages2.pageSize,
            }
            this.tableLoading2 = true
            getStaffsByUnitId(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.tableLoading2 = false
                    this.tableData2 = result.Response ? result.Response.Data : []
                    this.pages2.dataCount = result.Response ? result.Response.DataCount : 0
                }
            })
        },
        // 查看学习时长大于0人数
        viewStudyTimer(val) {
            this.addFormVisibleDialog3 = true
            this.tableLoading3 = false
            this.tableData3 = []
            this.needData = val
            this.getTableDataFun3()
        },
        getTableDataFun3() {
            var params = {
                socialUnitId: this.needData.ID,
                pageIndex: this.pages3.pageIndex,
                pageSize: this.pages3.pageSize,
            }
            this.tableLoading3 = true
            getLearnStaffsByUnitId(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.tableLoading3 = false
                    this.tableData3 = result.Response ? result.Response.Data : []
                    this.pages3.dataCount = result.Response ? result.Response.DataCount : 0
                }
            })
        },
        // 分页
        handleSizeChange(newSize){
            this.pages.pageSize = newSize   
            this.getTableDataFun()
        },
        handleCurrentChange(newPage){
            this.pages.pageIndex = newPage      
            this.getTableDataFun()
        },
        handleSizeChange1(newSize){
            this.pages1.pageSize = newSize   
            this.getTableDataFun1()
        },
        handleCurrentChange1(newPage){
            this.pages1.pageIndex = newPage      
            this.getTableDataFun1()
        },
        handleSizeChange2(newSize){
            this.pages2.pageSize = newSize   
            this.getTableDataFun2()
        },
        handleCurrentChange2(newPage){
            this.pages2.pageIndex = newPage      
            this.getTableDataFun2()
        },
        handleSizeChange3(newSize){
            this.pages3.pageSize = newSize   
            this.getTableDataFun3()
        },
        handleCurrentChange3(newPage){
            this.pages3.pageIndex = newPage      
            this.getTableDataFun3()
        },
        // 表格当前行改变事件
        handlerowChange(currentRow){
            this.currentRow = currentRow
        },
        // 选中表格行
        handleSelectChange(allRow){
            this.allSelect = allRow
        },
        // 获取岗位/行业
        getDataDictionaryList() {
            var params = {}
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    result.Response.forEach(item => {
                        if(item.DataType == 0) {
                            this.tradeArr.push(item)
                        } else if(item.DataType == 1) {
                            this.stationArr.push(item)
                        }
                    });
                }
            })
        },
        // 选中省、市、区当前行
        selProvince(value){
            this.filters.cityID = ''
            this.filters.regionID = ''
            this.parentProvinceID = value
            var params = {
                level:1,
                parentId:this.parentProvinceID
            }
            this.getCity(params)
        },
        selCity(value){
            this.parentCityID = value
            var params = {
                parentId:this.parentCityID
            }
            this.getArea(params)
        },
        getCity(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.cityIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        getArea(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.regionIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        currentChange(val) {
            this.brandListPage.pageIndex = val
        },
        // 时间格式化
        formatCreateTime:function(row,column){
            return !row.NoticeTime || row.NoticeTime == ""
            ? ""
            : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd");
        },
        getTimerFun() {
            var d = new Date();
            var curr_date = d.getDate();
            var curr_month = d.getMonth() + 1; 
            var curr_year = d.getFullYear();
            String(curr_month).length < 2 ? (curr_month = "0" + curr_month): curr_month;
            String(curr_date).length < 2 ? (curr_date = "0" + curr_date): curr_date;
            var yyyyMMdd = curr_year + "" + curr_month +""+ curr_date;
            return yyyyMMdd;
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
    },
    created(){
        this.getTableDataFun();
        this.getDataDictionaryList();
        var params = {
            parentId: 1919,
            level: 1,
        }
        this.getCity(params)
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .pageBarContainer {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    .selectNumCla {
        color: #409EFF;
        font-weight: bold;
        margin: 0 5px;
        font-size: 16px;
    }
</style>